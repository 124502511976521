import type { FC } from 'react';
import { useState } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

type FileUploadProps = {
  name: string;
  label: string;
  accept: string;
  onChange: (value: string) => void;
  className?: string;
};

const FileUpload: FC<FileUploadProps> = ({
  name,
  label,
  accept,
  onChange,
  className = '',
}) => {
  const [value, setValue] = useState('');

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
    onChange(target.value);
  };

  return (
    <div className={classnames(styles.container, {
      [className]: !!className,
    })}>
      <label className={styles.inputWrapper}>
        <span className={styles.label}>{label}</span>
        <input
          className={styles.input}
          type="file"
          name={name}
          accept={accept}
          value={value}
          onChange={handleChange}
        />
      </label>
      {value && (
        <button
          className={styles.reset}
          onClick={() => setValue('')}
        />
      )}
    </div>
  );
};

export default FileUpload;
