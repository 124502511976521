export const fileToB64 = (file: File): Promise<string> => new Promise((resolve) => {
  if (!file) {
    resolve('');
  }

  const reader = new FileReader();

  reader.onload = (e: ProgressEvent<FileReader>) => {
    const base64String = (e.target?.result as string).split(',')[1];

    resolve(base64String);
  };

  reader.readAsDataURL(file);
});
