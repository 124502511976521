import type { FC } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

type ButtonProps = {
  label: string;
  primary?: boolean;
  disabled?: boolean;
  type?: 'submit' | 'button';
  onClick?: React.MouseEventHandler;
  className?: string;
};

const Button: FC<ButtonProps> = ({
  label,
  primary,
  disabled,
  type = 'button',
  onClick,
  className = '',
}) => (
  <button
    className={classnames(styles.button, {
      [styles.primary]: primary,
      [styles.disabled]: disabled,
      [className]: className,
    })}
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    {label}
  </button>
);

export default Button;
