import { RUNPOD } from '../../config';
import { useMutation } from '@tanstack/react-query';

export type ImageData = {
  prompt: string;
  seed: number;
  image: string; // base64
  adapterStrength: number;
};

export const useImageGen = () => useMutation({
  mutationFn: ({ prompt, seed, image, adapterStrength }: ImageData) =>
    fetch(`${RUNPOD.domain}${RUNPOD.imagePath}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${RUNPOD.token}`,
      },
      body: JSON.stringify({
        input: {
          prompt,
          seed,
          ...(image ? { image, adapterStrength } : {}),
        },
      }),
    })
      .then((res) => res.json())
      .then(({ output }) => output.image),
});

export const useMeshGen = () => useMutation({
  mutationFn: (image: string) =>
    fetch(`${RUNPOD.domain}${RUNPOD.meshPath}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${RUNPOD.token}`,
      },
      body: JSON.stringify({
        input: {
          image,
        },
      }),
    })
      .then((res) => res.json())
      .then(({ output }) => output.obj),
      // .then(({ output }) => new Blob([output.obj], {
      //   type: 'model/obj',
      // })),
});
