import type { FC } from 'react';
import type { ImageData } from '../../repositories/Runpod';
import { useState } from 'react';
import {
  Button,
  Textarea,
  Select,
  Option,
  FileUpload,
  Slider,
} from '../Common';
import { fileToB64 } from '../../utils/processing';

import styles from './styles.module.scss';

type ImageFormProps = {
  onSubmit: (data: ImageData) => void;
  onUpload: (image: string) => void;
  loading: boolean;
};

const ImageForm: FC<ImageFormProps> = ({
  onSubmit,
  onUpload,
  loading,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [seed, setSeed] = useState(3407);
  const [adapterValue, setAdapterValue] = useState('');

  const promptElName = 'prompt';
  const seedElName = 'seedType';
  const adapterElName = 'adapterImage';
  const adapterStrengthElName = 'adapterStrength';

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);
    const prompt = formData.get(promptElName);
    const seedType = formData.get(seedElName);
    const image = formData.get(adapterElName);
    const adapterStrength = formData.get(adapterStrengthElName);
    const newSeed = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

    onSubmit({
      prompt: prompt as string,
      seed: seedType === 'random'
        ? newSeed
        : seed,
      image: await fileToB64(image as File),
      adapterStrength: Number(adapterStrength),
    });

    if (seedType === 'random') {
      setSeed(newSeed);
    }
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const base64String = (e.target?.result as string).split(',')[1];
        onUpload(base64String);
      };

      reader.readAsDataURL(file);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.tabs}>
        <Button className={`${styles.tab} ${activeTab === 0 ? styles.active : ''}`} label="AI image" onClick={() => setActiveTab(0)} />
        <Button className={`${styles.tab} ${activeTab === 1 ? styles.active : ''}`} label="Upload image" onClick={() => setActiveTab(1)} />
      </div>
      <div className={`${styles.panel} ${activeTab === 0 ? styles.active : ''}`}>
        <Textarea
          className={styles.prompt}
          name={promptElName}
          placeholder="Enter a description of your item"
          required
        />

        <FileUpload
          className={styles.adapter}
          name={adapterElName}
          label="Inspiration"
          accept=".png, .jpg, .jpeg"
          onChange={setAdapterValue}
        />

        {adapterValue && (
          <Slider
            className={styles.adapterStrength}
            name={adapterStrengthElName}
            label="Inspiration strength"
            min={0} max={100} step={5} unit="%" starting={50}
          />
        )}

        <div className={styles.footer}>
          <Select className={styles.seed} name={seedElName}>
            <Option value="fixed" label="Consistent" />
            <Option value="random" label="Creative" />
          </Select>

          <Button
            className={styles.submit}
            type="submit"
            disabled={loading}
            primary
            label="Generate Image"
          />
        </div>
      </div>

      <div className={`${styles.panel} ${activeTab === 1 ? styles.active : ''}`}>
        <input
          className={styles.upload}
          type="file"
          name="upload"
          accept=".png, .jpg, .jpeg"
          onChange={handleUpload}
        />
      </div>
    </form>
  );
};

export default ImageForm;
