import type { FC } from 'react';

import styles from './styles.module.scss';

type TextareaProps = {
  name: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
};

const Textarea: FC<TextareaProps> = ({
  name,
  placeholder = '',
  className = '',
  required = false,
}) => (
  <textarea
    className={`${styles.textarea} ${className}`}
    name={name}
    placeholder={placeholder}
    required={required}
  />
);

export default Textarea;
