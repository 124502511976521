import type { FC } from 'react';
import styles from './styles.module.scss';

type SelectProps = {
  name: string;
  children: React.ReactNode;
  className?: string;
};

const Select: FC<SelectProps> = ({
  name,
  children,
  className = '',
}) => {
  return (
    <select className={`${styles.select} ${className}`} name={name}>
      {children}
    </select>
  );
};

export default Select;
