import type { FC } from 'react';
import styles from './styles.module.scss';

type OptionProps = {
  label: string;
  value: string;
}

const Select: FC<OptionProps> = ({
  value,
  label,
}) => {
  return (
    <option value={value}>
      {label}
    </option>
  );
};

export default Select;
