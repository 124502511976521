import type { ImageData } from './repositories/Runpod';
import { useState } from 'react';
import ImageForm from './components/ImageForm';
import ImagePreview from './components/ImagePreview';
import { useImageGen, useMeshGen } from './repositories/Runpod';
import { Button } from './components/Common';
import ModelPreview from './components/ModelPreview';

import styles from './styles.module.scss';

const CONTACT_EMAIL = 'hi@objetstrouv.es';

const App = () => {
  const [imageUpload, setImageUpload] = useState<string>('');

  const { data: image, mutate: genImage, isPending: imagePending } = useImageGen();
  const { data: mesh, mutate: genMesh, isPending: meshPending } = useMeshGen();

  const handleImageFormSubmit = async (data: ImageData) => {
    genImage(data);
  };

  const handleGenerateMesh = () => {
    genMesh(imageUpload || image);
  };

  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <h1 className={styles.title}><img className={styles.icon} src="/favicon.webp" alt="" /> Prototype</h1>
        <p className={styles.subline}>
          Reach out to
          {' '}
          <a className={styles.contact} href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
          {' '}
          for more information.
        </p>
      </header>

      <div className={styles.image}>
        <div className={styles.form}>
          <ImageForm
            onSubmit={handleImageFormSubmit}
            onUpload={setImageUpload}
            loading={imagePending}
          />

          <Button
            className={styles.genmesh}
            primary
            disabled={!(imageUpload || image) || (imagePending || meshPending)}
            label="Generate mesh"
            onClick={handleGenerateMesh}
          />
        </div>
        <div className={styles.preview}>
          <ImagePreview image={imageUpload || image} isLoading={imagePending} />
        </div>
      </div>

      <ModelPreview model={mesh} loading={meshPending} />
    </div>
  );
};

export default App;
