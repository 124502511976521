import type { FC } from 'react';
import styles from './styles.module.scss';

type ImagePreviewProps = {
  image: string; // base64
  isLoading: boolean;
};

const ImagePreview: FC<ImagePreviewProps> = ({
  image,
  isLoading,
}) => {
  return (
    <div className={styles.container}>
      {!image && isLoading && (
        <div className={styles.loader}>Loading...</div>
      )}
      {!isLoading && image && (
        <img
          className={styles.image}
          src={`data:image/png;base64,${image}`}
          alt="Generated"
        />
      )}
    </div>
  );
}

export default ImagePreview;
