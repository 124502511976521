import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

import styles from './styles.module.scss';

type ModelPreviewProps = {
  model: Blob;
  loading: boolean;
};

const ModelPreview: FC<ModelPreviewProps> = ({
  model,
  loading,
}) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const modelUrl = window.URL.createObjectURL(new Blob([model], { type: 'text/plain' }));

  useEffect(() => {
    if (model && !loading) {
      const width = (previewRef.current as HTMLElement).offsetWidth;
      const height = (previewRef.current as HTMLElement).offsetHeight;

      const renderer = new THREE.WebGLRenderer();
      console.log(width, height);
      renderer.setSize(width, height);

      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        45,
        width / height,
        0.1,
        1000,
      );
      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;

      camera.position.set(0, 0, 1.5);
      controls.update();

      const axes = new THREE.AxesHelper();
      scene.add(axes);

      const loader = new OBJLoader();
      loader.load(modelUrl, (obj) => {
        obj.rotation.set(-1.5, 0, -1.6);
        scene.add(obj);
      });

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(1, 1, 1).normalize();
      scene.add(directionalLight);

      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();

        renderer.render(scene, camera);
      };
      animate();

      previewRef.current?.appendChild(renderer.domElement);

      window.addEventListener('resize', () => {
        const width = (previewRef.current as HTMLElement).offsetWidth;
        const height = (previewRef.current as HTMLElement).offsetHeight;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        renderer.setSize(width, height);
      });

      return () => {
        previewRef.current?.removeChild(renderer.domElement);
        controls.dispose();
      };
    }
  }, [model, modelUrl]);

  return (
    <div className={styles.container}>
      {!model && loading && (
        <div>Loading...</div>
      )}
      {model && !loading && (
        <>
          <div className={styles.canvas} ref={previewRef} />
          <a className={styles.download} href={modelUrl} download="mesh.obj">Download</a>
        </>
      )}
    </div>
  );
};

export default ModelPreview;

