import type { FC } from 'react';
import { useState } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

type SliderProps = {
  name: string;
  label: string;
  min: number;
  max: number;
  step: number;
  starting?: number;
  unit?: string;
  className?: string;
};

const Slider: FC<SliderProps> = ({
  name,
  label,
  min,
  max,
  step,
  starting = 0,
  unit = '',
  className = '',
}) => {
  const [value, setValue] = useState(starting);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(target.value);

    setValue(val);
  };

  return (
    <label className={classnames(styles.container, {
      [className]: !!className,
    })}>
      <span className={styles.label}>{label}</span>
      <input
        className={styles.input}
        name={name}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
      />
      <output className={styles.output}>{`${value}${unit}`}</output>
    </label>
  );
};

export default Slider;
